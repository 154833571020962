let environmentName = "staging2";

let config = {
    errorApiHost: "https://api." + environmentName + ".errors.closesimple.com/",
    adminUiHost: "https://admin." + environmentName + ".web.cdn.closesimple.com/",
    diagnosticUiHost: "https://" + environmentName + ".diagnostics.closesimple.com/",
    adminAuthApiHost: "https://api.auth" + environmentName + ".admin.closesimple.com/",
    adminAuthHost: "https://auth" + environmentName + ".admin.closesimple.com/",
    cognitoClientId: "77bgl0q2tvo89c6bn0ndkrmaln",
    searchLimit: 20,
}

config.tokenUrl = config.adminAuthHost + "authorize?response_type=code&client_id=" + config.cognitoClientId + "&redirect_uri=" + window.location.origin + "&scope=openid+profile+aws.cognito.signin.user.admin&idp_identifier=closesimple.com&state=";

config.getCompanyIdLink = (error) => {
    return `${config.adminUiHost}app/companies/${error.CompanyId}`
};

config.getOrderIdLink = (error) => {
    return `${config.diagnosticUiHost}company/${error.CompanyId}/order/${error.OrderId}`
};

module.exports = config;
